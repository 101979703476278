export function createCiderLink(maker, title)
{
  return '/' +maker + '#' + title;
}

export function createMakerLink(maker)
{
  return '/' +maker ;
}

