import * as listStyle from "./listAlpha.module.scss"
import React from "react"
import {createCiderLink, createMakerLink} from '../components/utils.js'
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { RatingControl } from "react-rating-control";
import { Link } from "gatsby"
import Helmet from 'react-helmet';

export default function Index({ data }) {
  const ciders = data.allMdx.nodes // All the perrys
  const makers = data.makers.nodes
  var i=1
  const title = 'List of Perry reviewed';

  return (
    <Layout pageTitle="Cider With Evans">
      <Helmet>
                <title>{title}</title>
                <meta name="description" content={title}/>
                <meta name="robots" content="nofollow"></meta>
      </Helmet>
      <h1 className={listStyle.title}>Perrys and Pear Ciders</h1>
      <div>
        {
          //loop through the cider reviews 
          <table  className={listStyle.listingTable}>
            {
             
              ciders.map((node) => (
                [
                <tr className={listStyle.tablerow}>
                  <td><div className={listStyle.count}>{i++}</div></td>
                  <td><div className={listStyle.link}>
                  <Link to={createMakerLink(node.frontmatter.maker)}>
                    {makers.find(m => m.frontmatter.maker === node.frontmatter.maker).frontmatter.title} 
                  </Link>
                  </div></td>
                  <td><div className={listStyle.link}> <Link to={createCiderLink(node.frontmatter.maker,node.frontmatter.title)}>{node.frontmatter.title}</Link></div></td>
                  <td><div className={listStyle.text}>{node.frontmatter.strength}% ABV.</div> </td>
                  <td><div className={listStyle.rating}>{node.frontmatter.rating != null &&
                         <RatingControl 
                         value = {node.frontmatter.rating}
                         primaryColor='gray'
                         secondaryColor='red'
                         fontsize='20px'
                         enabled={false}/>
                          }</div></td>
                </tr>               
                ]
              ))
            }
          </table>
        }
      </div>
    </Layout>
  )
}

export const query2 = graphql`
  query  {
    allMdx (filter: {frontmatter: { page_type: {eq: "review"},fruit: {eq: "pear"}}} sort: {fields: frontmatter___type})
    {
      nodes 
      {
        frontmatter {
          maker
          title
          strength
          type
          rating
        }
      }
    }
    makers: allMdx (filter: {frontmatter: { page_type: {eq: "maker"}}})
    {
      nodes 
      {
        frontmatter {
          maker
          title
        }
      }
    }   
  }
  `
